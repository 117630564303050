import React from 'react';
import { RedocStandalone } from 'redoc';
import spec from '../assets/spec.yaml'; // Adjust the path based on your project structure

const RedocComponent = () => (
  <div style={{ height: '100vh', width: '100%' }}>
    <RedocStandalone
      spec={spec}
      options={{
        scrollYOffset: 50,
        theme: { colors: { primary: { main: '#dd5522' } } },
      }}
    />
  </div>
);

export default RedocComponent;
