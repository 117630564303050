import 'core-js/stable';
import 'regenerator-runtime/runtime';

import {
  APP_INIT_ERROR, APP_READY, subscribe, initialize,
} from '@edx/frontend-platform';
import ReactDOM from 'react-dom';
import { Route, Routes } from 'react-router-dom';
import { PageWrap, AppProvider, ErrorPage } from '@edx/frontend-platform/react';

import Header from '@edx/frontend-component-header';
import messages from './i18n';
import ExamplePage from './example/ExamplePage';
import RedocComponent from './redoc';

import './index.scss';

subscribe(APP_READY, () => {
  ReactDOM.render(
    <AppProvider>
      <Header />
      <Routes>
        <Route
          path="/"
          element={<PageWrap><RedocComponent /></PageWrap>}
        />
        <Route
          path="/docs"
          element={<PageWrap><RedocComponent /></PageWrap>}
        />
        <Route path="*" element={<PageWrap><ExamplePage /></PageWrap>} />
      </Routes>
    </AppProvider>,
    document.getElementById('root'),
  );
});

subscribe(APP_INIT_ERROR, (error) => {
  ReactDOM.render(<ErrorPage message={error.message} />, document.getElementById('root'));
});

initialize({
  messages,
});
